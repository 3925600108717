import * as React from "react"
// import Slider from "react-slick";
import Layout from "../components/layout"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import Title from "../components/title"
import { Link } from "gatsby"

const CarLashing = ({ data }) => {
  const pageData = {
    titleClass: "car_lashing",
    title: "CAR LASHING",
    discription: "",
  }
  const seodata = {
      title: "全く新しい発想で誕生したCAR LASHING[カーラッシング]",
      seo: {
        description: "アヴィエランが開発した「ひねりフック」を標準装備した、 全く新しい発想で誕生したカーラッシング。 この「ひねりフック」により、これまで以上に 安心・安全な固縛が可能。",
      },
      image: {
        url: data.datoCmsImage.topimage[21].url,
      },
      slug: "contact",
  }
  // const newsData = data.allDatoCmsNews.nodes

  return (
    <Layout pagestyle={pageData} seo={seodata}>
        <Title titlestyle={pageData} />
<div className="car_lashing">
		<div className="contents_top">

            <p className="language_switching"><Link to="/car-lashing_en">Click here for English version </Link></p>
			<div className="main_title">
				<div className="title01">
					<p className="logoimg"><img src="https://www.slingbelt.com/template/default/img/car_lashing/logo.png" alt="car lashing" /></p>
					<h1>CAR LASHING<span>カーラッシング</span></h1>
					<h2>アヴィエランが開発した「ひねりフック」を標準装備した、
						全く新しい発想で誕生したカーラッシング。
						この「ひねりフック」により、これまで以上に
						安心・安全な固縛が可能。</h2>
					<p><span className="mark1">フック</span></p>
				</div>
					<div className="main_circle">
						<h3>世界初の<br />
							<span>「ひねりフック」</span><br />
							で特許を取得！</h3>
							<p>特許番号／6216484号</p>
					</div>
			</div>
			<div className="mainimg">
				<img src="https://www.slingbelt.com/template/default/img/car_lashing/A1.png" alt="car lashing" />
				<div className="img_comment">
					<p className="title3">安心・安全な固縛を
						可能にした工夫は
						この「ひねり」。</p>
				</div>
			</div>
		</div>
		<div className="detail_box">

			<div className="item_detail">
				<div className="datail_comment">
					<p className="title">特長1</p>
					<p className="text_size2 text_w">ひねっているから、</p>
					<p className="text_size1 text_red text_w">揺れ・振動に強い！</p>
					<p className="text_size3 text_w">この「ひねりフック」は、知恵の輪をはずすように、意図的に斜めにひねりながら抜くことで外れるように設計されています。</p>
					<p className="text_size4">固縛時、上から見るとフック先端が大きくひねっていることが確認できます。輸送時に縦方向の揺れや振動が起きた場合でも、フック先端のひねり部分がアイボルトに引っ掛かり、非常に外れにくくなっています。</p>
	
				</div>
				<div className="datail_img">
					<p className="title2">固縛対象の固定部材（アイボルト等）に上掛けした状態</p>
					<p className="img_hook"><span className="mark1">上から見たところ</span><img src="https://www.slingbelt.com/template/default/img/car_lashing/A2.png" alt="car lashing" /></p>
					<p className="img_hook"><span className="mark1">横から見たところ</span><img src="https://www.slingbelt.com/template/default/img/car_lashing/A3.png" alt="car lashing" /></p>

				</div>
			</div>
			<div className="item_detail">
				<div className="datail_comment">
					<p className="title">特長2</p>
					<p className="text_size2 text_w">ひねっているから、</p>
					<p className="text_size1 text_red text_w">下掛けにも強い！</p>
					<p className="text_size3 text_w">この「ひねりフック」は、基本は上掛けが必須ですが、固縛対象の固定部材側の仕様により、下掛けしかできない場合でも、ひねり効果で外れにくくなっています。</p>
					<p><img src="https://www.slingbelt.com/template/default/img/car_lashing/A4.jpg" alt="car lashing" /></p>
					<p className="text_size4"><span className="mark1 text_size5">下掛け使用時</span>ベルトに適正なテンションが掛かっている状態</p>
	
				</div>
				<div className="datail_img">
					<p className="title2">ベルトのテンションが緩んだ時</p>
					<div className="datail_img2">
						<p><img src="https://www.slingbelt.com/template/default/img/car_lashing/A6.jpg" alt="car lashing" /></p>
						<p>螺旋状に曲がっている部分（図の赤〇で囲んだ部分）で一時的に止まろうとします。</p>
					</div>
					<div className="datail_img2">
						<p><img src="https://www.slingbelt.com/template/default/img/car_lashing/A5.jpg" alt="car lashing" /></p>
						<p>更に青○で囲んだ部分の重みでフックのベルト側が下にさがり、掛かった状態を維持しようとします。</p>
					</div>
				</div>
			</div>
			<div className="detail_attention">
				<p className="text_size4"><span className="text_size5 mark2">注意事項</span>フックは必ずしっかりと掛かった状態（特長１の画像を参照ください）でご使用ください。フックがしっかりと掛かっていないと、フックが外れたり、性能を十分に発揮できなくなる恐れがあります。</p>
			</div>
		</div>
		<div className="contents_top contents_top2">


			<div className="main_title">
				<div className="title01">
					<div className="title_spec">
						<p className="title3 text_size1">さらにタフに、安全に</p>
						<p className="text_size5">従来の一般的な<br />
							セット破断強度
							<span>2.0t</span>
						</p>
						<p className="text_size5">アヴィエラン<br />カーラッシング
							セット破断強度
							<span>3.0t</span>
						
						</p>
					</div>
					<h2>安心・安全が詰まった
						カーラッシング誕生。</h2>
					<p><span className="mark1">バックル</span></p>
				</div>

			</div>
			<div className="mainimg">
				<img src="https://www.slingbelt.com/template/default/img/car_lashing/B1.png" alt="car lashing" />
				<div className="img_comment">
					<div className="spec">
						<p>仕様</p>
						<p>ベルト幅/ 25mm</p>
						<p>ベルト長/ 1.4m</p>
						<p>総重量/ 約 840g</p>
					</div>
					<div className="spec">
						<p>破断強度</p>
						<p>バックル/ 3t</p>
						<p>ベルト/ 3.8t</p>
						<p>フック/ 3.4t</p>
						<p>セット破断/ 3t</p>

					</div>
				</div>
			</div>
		</div>
		<div className="detail2">
			<div className="detail2_box_waku">
				<div className="detail2_box">
					<div className="w50">
						<p className="text_size2 title3">高いガイドで
							ベルトが
							収まりやすい</p>
						<p className="text_size5">作業時の負担を
							軽減するために</p>
						<p className="text_size2 text_red text_w">ベルトが
							収まりやすい
							形状にしました。</p>
					</div>
					<div className="w50">
						<img src="https://www.slingbelt.com/template/default/img/car_lashing/B3.jpg" alt="car lashing" />
					</div>
				</div>
			</div>

			<div className="detail2_box_waku">

				<div className="detail2_box">
					<div className="w100 row">
						<img src="https://www.slingbelt.com/template/default/img/car_lashing/B5.jpg" alt="car lashing" />
					</div>
					<div className="w100">
						<p className="text_size2 title3">二重の安全対策
						</p>
						<p className="text_size5">アーム部のロック機構に加えベルトストッパーを装備したことで</p>
						<p className="text_size2 text_red text_w">バックルが開放するリスクを
							低減しました。</p>
					</div>
				</div>
			</div>

			<div className="detail2_box_waku">
				<div className="detail2_box">
					<div className="w100 row">
						<p className="text_size2 title3">ステンレスプレートでベルト長持ち</p>
					</div>
					<div className="w50">
						<p className="text_size5">錆びにくいステンレス製のプレートを採用し、作業時の</p>
						<p className="text_size2 text_red text_w">錆びによる
							ベルトの摩耗を
							抑えます。</p>
					</div>
					<div className="w50">
						<img src="https://www.slingbelt.com/template/default/img/car_lashing/B4.jpg" alt="car lashing" />
					</div>
				</div>

			</div>
			<div className="detail2_box_waku">
				<div className="detail2_box">
					<div className="w100 row">
						<p className="text_size2 title3">優しい表面処理で安心</p>
					</div>
					<div className="w100">
						<p className="text_size5">固縛作業時に、バックルが固縛対象物に接触した場合でも
							傷が付きにくくするため、バックルのグリップとフックに</p>
						<p className="text_size2 text_red text_w">表面コーティングを施しました。</p>
					</div>
				</div>
			</div>
		</div>

		<div className="contents_top3">
			<div className="mainimg">
				<img src="https://www.slingbelt.com/template/default/img/car_lashing/B2.png" alt="car lashing" />
			</div>
		</div>
		<div className="detail2">
			<div className="detail2_box_waku">
				<div className="detail2_box">
					<div className="w100">
						<p className="text_size2 title3">使用限度ラインで安心</p>
						<p className="text_size5">ベルト内部の赤糸ラインで使用限界を目視確認できます。
							損傷により赤糸が露出したら交換してください。
							始業点検・定期点検を必ず行ってご使用下さい。</p>
					</div>
					<div className="w100">
						<img src="https://www.slingbelt.com/template/default/img/car_lashing/B6.png" alt="car lashing" />
					</div>
				</div>
			</div>

			<div className="detail2_box_waku">
				<div className="detail2_box">
					<div className="w100">
						<p className="title4">option</p>
						<p className="text_size2 title3">万一フックが掛かりにくい場合も安心</p>
						<p className="text_size5">フックが掛かりにくい固縛対象物には、ストラップでサポートします。
							カーラッシングのベルトに
							直付けされていますので、
							盗難・紛失の心配がありません。</p>
					</div>
					<div className="w100">
						<img src="https://www.slingbelt.com/template/default/img/car_lashing/B7.png" alt="car lashing" />
					</div>
				</div>
			</div>
		</div>
	</div>
      <div className="contact_">
        <div className="tel_contact">
          <p><span>お電話でのお問い合わせ</span></p>
          <p>052-701-6711</p>
          <p>平日：10時～16時</p>
          <p>土・日・祝日は基本的にお休みとさせて頂きます。</p>
        </div>
        <form 
          name="contact" 
          method="POST" 
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          action="/thanks/"
        >
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />

          <div className="form-group">

            <label><span>会社名<b>※</b></span>
            <input type="text" className="form-control" id="company" name="company" placeholder="会社名" maxLength="30" minLength="2" required />
            </label>

            <label><span>部署名</span>
            <input type="text" className="form-control" id="div-name" name="div-name" placeholder="部署名" maxLength="30" minLength="2"/>
            </label>

            <label><span>お名前<b>※</b></span>
            <input type="text" className="form-control" id="name" name="name" placeholder="お名前" maxLength="30" minLength="2" required autoComplete="name" />
            </label>

            <label><span>メールアドレス<b>※</b></span>
            <input type="email" className="form-control" id="email" name="email" placeholder="" pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" required autoComplete="email" />
            </label>

            <label><span>電話番号</span>
            <input type="text" className="form-control" id="telnum" name="telnum" />
            </label>

            <label><span>お問い合わせ内容<b>※</b></span>
            <textarea className="form-control" id="contact" name="content" rows="10" required></textarea>
            </label>
          </div>

          <div className="form-btn">
          <button type="submit">送信</button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default CarLashing

export const query = graphql`

  query   {
    allDatoCmsNews{
      nodes {
        title
        data(formatString: "YYYY/MM/DD")
        meta {
          createdAt(formatString: "YYYY/MM/DD")
          updatedAt(formatString: "YYYY/MM/DD")
        }
      }
    }
    datoCmsImage {
      topimage {
        url
        fluid(maxWidth: 1800, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
